@use "sass:math";

.pdw-slider {
  position: relative;

  .pdw-slider-track {
    .pdw-slider-items {
      .pdw-slider-item {
        white-space: normal;
      }
    }
  }

  .pdw-slider-controls {
    &.pdw-slider-controls-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      opacity: 0;
      transition: opacity .15s;
    }

    .pdw-slider-next,
    .pdw-slider-prev {
      color: var(--#{$prefix}primary);
      width: 1rem;
      cursor: pointer;
      pointer-events: all;
      opacity: 0.75;
      transition: opacity .15s;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover {
    .pdw-slider-controls {
      &.pdw-slider-controls-overlay {
        opacity: 1;
      }
    }
  }

  .pdw-slider-bullets {
    gap: $spacer*.5;
    margin-bottom: $spacer;
    padding: 0;

    > li {
      &:first-child {
        &:last-child {
          display: none;
        }
      }
    }

    .splide__pagination__page {
      appearance: none;
      display: block;
      padding: 0;
      margin: 0;
      border: none;
      width: .5rem;
      height: .5rem;
      border-radius: 500px;
      background: var(--#{$prefix}primary);
      opacity: .5;

      &.is-active {
        opacity: 1;
      }
    }
  }
}