//Color and opacity transition for all
html body *:after,
html body *:before,
html body * {
  //transition: color .3s, background-color .3s, border-color .3s, opacity .3s, outline-color .3s;
}

//Smooth scroll
html {
  scroll-behavior: smooth;
}

//Button
[type=button], [type=reset], [type=submit], button {
  appearance: none;
}
.pdw-btn {
  display: inline-flex;
  align-items: center;
  gap: $spacer*.5;
}

//Page header image
.page-header {
  background-size: cover;
  background-position: center center;
}

//Wordpress
.screen-reader-text {
  display: none;
}

.pagination {
  justify-content: center;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

//Navbar
.navbar {
  .nav-link {
    white-space: nowrap;
  }
}

//Logo
.pdw-logo-link {
  max-width: 45vw;
}