//Hover (use .ht-1, ..., .hl-2 etc. .hl-md-1 can be used too, example on ipoteca.be and copilot.be)
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: h) {
      @each $size, $length in $spacers {

        .#{$abbrev}t#{$infix}-#{$size} {
          #{$prop}-top: -$length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size} {
          #{$prop}-right: -$length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size} {
          #{$prop}-bottom: -$length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size} {
          #{$prop}-left: -$length !important;
        }

      }
    }
  }
}

.above {
  position: relative;
  z-index: 999 !important;
}