@use 'sass:math';

@mixin pdw-reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin pdw-simple-horizontal-nav {
  @include pdw-reset-ul;
  display: flex;
  align-items: center;
  margin: 0 math.div($spacer, 2);
  ul {
    @include pdw-reset-ul;
    display: flex;
    align-items: center;
  }
  li {
    display: flex;
    align-items: center;
  }
  a {
    display: block;
    margin: 0 math.div($spacer, 2);
    white-space: nowrap;
    color: $black;

    &:hover {
      color: $theme-color-1;
    }
  }
}

@mixin pdw-horizontal-nav($pdw-horizontal-nav-color: $theme-color-1,$pdw-horizontal-nav-spacer: 15px) {
  @include pdw-reset-ul;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: $pdw-horizontal-nav-spacer;
  ul {
    @include pdw-reset-ul;
  }
  > li {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $pdw-horizontal-nav-spacer;
      @include media-breakpoint-down(xl) {
        padding: $spacer math.div($spacer, 2);
        letter-spacing: -.5px;
      }
    }

    > ul {
      pointer-events: none;
      position: absolute;
      z-index: 1001;
      top: 100%;
      left: 0;
      background: $white;
      box-shadow: $box-shadow-sm;
      width: 300px;
      transition: all .5s;
      transform: translateY(-$pdw-horizontal-nav-spacer*2);
      opacity: 0;

      a {
        padding: math.div($pdw-horizontal-nav-spacer, 2) $pdw-horizontal-nav-spacer;
        white-space: normal;
      }

      ul {
        font-size: .75em;

        a {
          &:before {
            content: '- ';
          }
        }
      }
    }

    &:last-child {
      > ul {
        left: unset;
        right: 0;
      }
    }

    &:hover {
      > ul {
        pointer-events: all;
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  a {
    display: block;
    white-space: nowrap;
    text-decoration: none;
    color: $black;
    transition: color .3s, background-color .3s;

    &:hover {
      background: $pdw-horizontal-nav-color;
      border-color: $pdw-horizontal-nav-color;
      color: color-contrast($pdw-horizontal-nav-color);
      text-decoration: none;
    }
  }
  li {
    &.current-menu-item {
      > a {
        background: $pdw-horizontal-nav-color;
        color: color-contrast($pdw-horizontal-nav-color);
      }
    }
  }
}

.pdw-desktop-menu {
  @include pdw-horizontal-nav;
}