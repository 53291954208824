@use 'sass:math';

$pdw-mobile-menu-close-size: 2rem !default;
$pdw-mobile-menu-font-family: $headings-font-family !default;
$pdw-mobile-menu-font-size: 1.25rem !default;
$pdw-mobile-menu-font-weight: bold !default;
$pdw-mobile-submenu-font-weight: normal !default;
$pdw-mobile-menu-z-index: 100000 !default;
$pdw-mobile-menu-width: 300px !default;
$pdw-mobile-menu-background: var(--#{$prefix}primary) !default;
$pdw-mobile-menu-color: color-contrast($primary) !default;
$pdw-mobile-menu-box-shadow: 0 0 10px rgba(0, 0, 0, .3) !default;

@keyframes menu-item-show {
  50% {
    opacity: .25;
  }
  100% {
    opacity: 1;
  }
}

#pdw-mobile-menu {
  font-family: $pdw-mobile-menu-font-family;
  z-index: $pdw-mobile-menu-z-index;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: $pdw-mobile-menu-width;
  max-width: 100%;
  background: $pdw-mobile-menu-background;
  color: $pdw-mobile-menu-color;
  padding: 0 $spacer;
  box-shadow: 0 0 0 transparent;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all .3s;
  transform: translateX($pdw-mobile-menu-width);

  &.show {
    box-shadow: $pdw-mobile-menu-box-shadow;
    transform: translateX(0);

    + .pdw-mobile-menu-overlay {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, .3);
      z-index: ($pdw-mobile-menu-z-index)-1;
    }

    ul.mobile-menu > li {
      animation-name: menu-item-show;
      animation-duration: .25s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;

      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          animation-delay: $i * .1s;
        }
      }
    }
  }

  &:after, &:before {
    content: ' ';
    display: table;
  }

  &.top {
    width: 100%;
    @include media-breakpoint-up(md) {
      padding-left: 10%;
      padding-right: 10%;
    }
    transform: translateY(-100%);

    &.show {
      transform: translateY(0);
    }
  }

  ul.mobile-menu {
    width: 100%;
    max-width: 600px;
    list-style: none;
    padding: $spacer*2 0;
    margin: 0 auto;

    li a {
      text-decoration: none;
      align-items: center;

      > i {
        margin-right: .35em;
        margin-top: .25em;
      }
    }

    > li {
      padding: 0;
      margin: 0;
      opacity: 0;

      &:last-child a {
        border-bottom: 0;
      }

      a {
        display: flex;
        color: $pdw-mobile-menu-color;
        padding: $spacer;
        border-bottom: 1px solid rgba($pdw-mobile-menu-color, .15);
        font-size: $pdw-mobile-menu-font-size;
        line-height: 1.25;
        font-weight: $pdw-mobile-menu-font-weight;

        &:hover {
          text-decoration: none;
        }
      }

      ul.sub-menu {
        list-style: none;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid rgba($pdw-mobile-menu-color, .15);

        li {
          padding: 0;
          margin: 0;
        }
      }
    }

    .current-menu-item > a {
      opacity: .75;
      background: rgba($primary, .05);
    }
  }

  .pdw-mobile-menu-close {
    position: absolute;
    top: $spacer;
    right: $spacer;
    padding: 0;
    margin: 0;
    border: 1px solid rgba($pdw-mobile-menu-color, .3);
    color: $pdw-mobile-menu-color;
    font-size: $pdw-mobile-menu-close-size*.9;
    width: $pdw-mobile-menu-close-size;
    height: $pdw-mobile-menu-close-size;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: math.div($pdw-mobile-menu-close-size, 2);
    cursor: pointer;
    appearance: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .pdw-mobile-menu-close-icon {
    position: relative;
    width: math.div($pdw-mobile-menu-close-size, 2);

    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: math.div($pdw-mobile-menu-close-size, 2);
      height: 1px;
      background: $pdw-mobile-menu-color;
      transform-origin: center center;
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }
}

#pdw-mobile-menu {
  ul.mobile-menu:not( .mobile-drilldown-menu ) {
    > li {
      ul.sub-menu {
        li {
          a {
            display: flex;
            color: $pdw-mobile-menu-color;
            padding: math.div($spacer, 2);
            font-size: 1rem;
            line-height: 1.25;
            font-weight: $pdw-mobile-submenu-font-weight;

            &:hover {
              text-decoration: none;
            }
          }

          ul.sub-menu {
            padding: 0 0 0 $spacer;
            border: 0;
          }
        }
      }
    }
  }
}

.pdw-mobile-menu-open {
  cursor: pointer;
}

#pdw-mobile-menu ul.mobile-drilldown-menu {
  position: relative;

  li {
    position: unset;

    &.menu-item-has-children {
      a {
        position: relative;

        .pdw-submenu-open {
          @include pdw-svg-icon('#{$pdw-icons-path}pdw-icon-chevron-right.svg');
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          aspect-ratio: 1;
        }
      }

      .pdw-submenu-close {
        position: relative;
        cursor: pointer;
        display: block;
        padding: $spacer $spacer*2;
        text-align: center;
        letter-spacing: .1em;
        text-transform: uppercase;
        border-bottom: 1px solid rgba($pdw-mobile-menu-color, .15);
        font-family: $headings-font-family;
        font-size: 1.25rem;
        @include pdw-svg-icon('#{$pdw-icons-path}pdw-icon-chevron-left.svg');

        &:before {
          position: absolute;
          left: $spacer;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &.open {
      ~ li {
        position: relative;
        z-index: -1;
      }

      > ul.sub-menu {
        transform: translateX(0);
        opacity: 1;
        pointer-events: all;
      }
    }

    ul.sub-menu {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $pdw-mobile-menu-background;
      z-index: 1;
      pointer-events: none;
      transform: translateX(100%);
      opacity: 0;
      transition: all .25s;
    }
  }
}