@use "sass:math";

.pdw-carousel {
  position: relative;

  .pdw-carousel-track {
    margin-top: $spacer;
    margin-bottom: $spacer;

    .pdw-carousel-items {
      .pdw-carousel-item {
        white-space: normal;
        //VC
        .wpb_button, .wpb_content_element, ul.wpb_thumbnails-fluid > li {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .pdw-carousel-controls {
    &.pdw-carousel-controls-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      opacity: 0;
      transition: opacity .15s;
    }

    .pdw-carousel-next,
    .pdw-carousel-prev {
      color: var(--#{$prefix}primary);
      width: 1rem;
      cursor: pointer;
      pointer-events: all;
      opacity: 0.75;
      transition: opacity .15s;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover {
    .pdw-carousel-controls {
      &.pdw-carousel-controls-overlay {
        opacity: 1;
      }
    }
  }

  .pdw-carousel-bullets {
    gap: $spacer*.5;
    margin-bottom: $spacer;
    padding: 0;

    > li {
      &:first-child {
        &:last-child {
          display: none;
        }
      }
    }

    .splide__pagination__page {
      appearance: none;
      display: block;
      padding: 0;
      margin: 0;
      border: none;
      width: .5rem;
      height: .5rem;
      border-radius: 500px;
      background: var(--#{$prefix}primary);
      opacity: .5;

      &.is-active {
        opacity: 1;
      }
    }
  }
}