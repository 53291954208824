.pdw-avis {
  transition: .3s ease-in;

  &:hover {
    background: $primary !important;
    color: $white !important;
  }

  &.bg-primary {
    &:hover {
      background: $white !important;
      color: $primary !important;
    }
  }
}

.pdw-bg-triangle {
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: url('../../../../assets/img/static/pattern-arvld-2.png') no-repeat;
    background-size: cover;
    width: 50%;
    height: 100%;
    z-index: -1;
  }
}

.splide__slide {
  height: 65vh !important;
}

.bg-tertiary{
  background: #FF9149;
}