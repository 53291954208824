//Mixins
@mixin pdw-svg-icon($svg,$color:currentColor,$width:1em,$height:1em,$display:block) {
  position: relative;
  &:before {
    mask: url(#{$svg});
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: contain;
    display: $display;
    content: "";
    width: $width;
    height: $height;
    background: $color;
  }
}

@mixin pdw-svg-icon-after($svg,$color:currentColor,$width:1em,$height:1em,$display:block) {
  position: relative;
  &:after {
    mask: url(#{$svg});
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: contain;
    display: $display;
    content: "";
    width: $width;
    height: $height;
    background: $color;
  }
}