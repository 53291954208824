/*
 * Icon libraries
 */
//FontAwesome
//$fa-font-path: '~font-awesome/fonts';
//@import '~font-awesome/scss/font-awesome';
//Linearicons
//@import '../../fonts/linearicons/linearicons';

/*
 * Font families
 */
//@import '../../fonts/roboto/roboto';
//$theme-font-1: $font-roboto;
$theme-font-base: "Instrument Sans", sans-serif;
$theme-font-headings: "Inter", sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Inter:wght@100..900&display=swap');
/*
 * Colors
 */
//Primary
$theme-color-1: #005657;
//Secondary
$theme-color-2: #FF9149;

/*
 * Border radius
 */
//$border-radius: 0;
//$border-radius-sm: 0;
//$border-radius-lg: 0;
//$border-radius-xl: 0;
//$border-radius-2xl: 0;
//$border-radius-pill: 0;

/*
 * Font sizes
 */
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;
$h6-font-size: $font-size-base;

/*
 * Accordion
 */
$accordion-body-padding-x: 0;

/*
 * Input focus
 */
$input-btn-focus-width: 0;