/*
 * SVG icons
 * pdw-icon-name.svg
 */
$pdw-icons-path: '../../img/icons/' !default;
$pdw-icon-names: (
        "burger",
        "cart",
        "account",
        "search",
        "phone",
        "email",
        "chevron-left",
        "chevron-right",
        "facebook",
        "tiktok",
        "linkedin",
        "youtube",
        "instagram"
) !default;

$pdw-icon-sizes: (
        "16",
        "20",
        "24",
        "40",
        "48",
) !default;

//Generate icon classes
@each $name in $pdw-icon-names {
  .pdw-icon-#{$name} {
    @include pdw-svg-icon('#{$pdw-icons-path}pdw-icon-#{$name}.svg');
  }
}

//Generate icon size classes
@each $size in $pdw-icon-sizes {
  .pdw-icon-#{$size} {
    &:before {
      width: #{$size}px;
      height: #{$size}px;
    }
  }
}